import React, { useState, useEffect } from 'react'
import Subscriber from "./Subscriber"

function SubscribersAdmin({ publication = {} }) {
  const [current, setCurrent] = useState(false)
  const [subscriberList, setSubscriberList] = useState(publication.subscribers)
  const [exportPublicationPath, setExportPublicationPath] = useState(publication.exportPublicationPath)

  const toggleCurrent = (() => {
    setCurrent(!current)
  })

  useEffect(() => {
    if (current) {
      setSubscriberList(publication.subscribers.filter(subscriber => subscriber.current === true))
      setExportPublicationPath(`${publication.exportPublicationPath}?current=current`)
    } else {
      setSubscriberList(publication.subscribers)
      setExportPublicationPath(publication.exportPublicationPath)
    }
  }, [current, publication.exportPublicationPath, publication.subscribers])

  return (
    <>
      <h2>
        { current ? "Current" : "All" } subscribers
      </h2>

      <nav>
        <a href={publication.editPublicationPath} className="btn btn-primary">Edit</a>
        {' '}
        <a href={exportPublicationPath} className="btn btn-default">
          Export {current ? "Current" : "All"}
        </a>
        {' '}
        <div>
          <label>
            <input
              type="checkbox"
              value={current}
              onChange={toggleCurrent} /> Current subscriptions only
            </label>
        </div>
      </nav>

      <table className="table">
        <thead>
          <tr>
            <th className="col-md-3">Last name</th>
            <th className="col-md-3">First name</th>
            <th className="col-md-3">Expiration</th>
            <th className="col-md-3 actions">Actions</th>
          </tr>
        </thead>
        <tbody>
          {subscriberList.map((props, i) => (
            <Subscriber key={i} {...props} />
          ))}
        </tbody>
      </table>
    </>
  )
}

export default SubscribersAdmin
