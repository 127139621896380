import React from "react"

export default function Subscriber({
    firstname,
    lastname,
    ends_on,
    profile_path,
  }) {
    return(
      <tr>
        <td>{lastname}</td>
        <td>{firstname}</td>
        <td>{ends_on}</td>
        <td className="actions">
          <a className="btn btn-primary btn-xs" href={profile_path}>View</a>
        </td>
      </tr>
    )
}
